import ApiHelper from './index';

const getLongRunningTasks = async (spinner = true, queryParams = {}) => {
  return await ApiHelper.get(
    'generic/long_running_task/',
    spinner,
    queryParams
  );
};

export default {
  getLongRunningTasks,
};
