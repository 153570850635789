import httpClient from '../../../../../service/httpClient';
import axios from 'axios';

export const filterRectanglePropsFromObject = (object) => {
  const {
    rotation,
    stroke,
    strokeScaleEnabled,
    scaleX,
    scaleY,
    ...res
  } = object;
  if (res.hasOwnProperty('is_static') && !res.is_static) {
    res['cordinates_of_static_object'] = null;
  }
  return res;
};

export const isObjectStatic = (object) => {
  return object.is_static === true;
};

export const isDraggable = (object) => {
  if (!object.is_static) return true;
  else return !object.cordinates_of_static_object;
};

export const isObjectMoved = (initialObject, object2) => {
  if (
    !initialObject.cordinates_of_static_object &&
    object2.cordinates_of_static_object
  )
    return true;
  return JSON.stringify(initialObject) === JSON.stringify(object2);
};

export const sameObjectIDs = (obj1, obj2) => {
  return obj1.id === obj2.id;
};

export const nestCoordinates = (object) => {
  const {
    id,
    name,
    is_static,
    cordinates_of_static_object,
    ...dimensions
  } = object;

  const returnObj = {
    id: id,
    name: name,
    is_static: is_static,
    cordinates_of_static_object: { ...dimensions },
  };
  if (object.hasOwnProperty('actual_id')) {
    returnObj['actual_id'] = object.actual_id;
  }
  return returnObj;
};

export const removeCoordinates = (object) => {
  const { cordinates_of_static_object, ...rest } = object;
  return rest;
};

export const hasCoordinates = (object) => {
  if (object.cordinates_of_static_object === null) return false;
  if (JSON.stringify(object.cordinates_of_static_object) === JSON.stringify({}))
    return false;
  return true;
};

export const isSameObject = (obj1, obj2) => {
  return obj1.id === obj2.id;
};

export const flattenNestedCoordinates = (object) => {
  const { cordinates_of_static_object, ...remaining } = object;
  return {
    ...remaining,
    ...cordinates_of_static_object,
  };
};

export const isObjectDisabled = (object) => {
  if (!object.is_static) return { [object.id]: false };
  if (object.is_static && object.cordinates_of_static_object == null)
    return { [object.id]: false };
  return { [object.id]: true };
};

export const mapStaticObjects = (object, task, newObject) => {
  const { id, cordinates_of_static_object, ...obj } = object;
  let modelObject = {
    ...obj,
    cordinates_of_static_object: JSON.stringify(cordinates_of_static_object),
    task: task,
  };
  if (!newObject) {
    modelObject['id'] = id;
  }
  return modelObject;
};
